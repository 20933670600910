import { MappedObject } from '@/types/global';

export const ATM_LOCATIONS_CACHE_KEY = 'ATM_LOCATIONS';

export const AWS_BUCKET_OBJECTS_LIST_CACHE_KEY = 'AWS_BUCKET_OBJECTS_LIST';

export const PROVINCE_KEY_TO_SLUG_MAPPING: Partial<MappedObject<string>> = {
  BC: 'british-columbia',
  ON: 'ontario',
  NL: 'newfoundland-and-labrador',
  NS: 'nova-scotia',
  PE: 'prince-edward-island',
  NB: 'new-brunswick',
  QC: 'quebec',
  MB: 'manitoba',
  SK: 'saskatchewan',
  AB: 'alberta',
  NT: 'northwest-territories',
  NU: 'nunavut',
  YT: 'yukon-territory',
};

export const AU_STATE_TO_SLUG_MAPPING: Partial<MappedObject<string>> = {
  ACT: 'australian-capital-territory',
  NSW: 'new-south-wales',
  NT: 'northern-territory',
  QLD: 'queensland',
  SA: 'south-australia',
  TAS: 'tasmania',
  VIC: 'victoria',
  WA: 'western-australia',
};

export const NZ_REGION_TO_SLUG_MAPPING: Partial<MappedObject<string>> = {
  NTL: 'northland',
  AUK: 'auckland',
  WAI: 'waikato',
  BOP: 'bay-of-plenty',
  HKB: 'hawkes-bay',
  TKI: 'taranaki',
  MWT: 'manawatu-wanganui',
  WGN: 'wellington',
  TAS: 'tasman',
  NSN: 'nelson',
  MBH: 'marlborough',
  WTC: 'west-coast',
  CAN: 'canterbury',
  OTA: 'otago',
  STL: 'southland',
};

export const STATE_KEY_TO_SLUG_MAPPING: Partial<MappedObject<string>> = {
  AL: 'alabama',
  AK: 'alaska',
  AZ: 'arizona',
  AR: 'arkansas',
  CA: 'california',
  CO: 'colorado',
  CT: 'connecticut',
  DE: 'delaware',
  DC: 'district-of-columbia',
  FL: 'florida',
  GA: 'georgia',
  HI: 'hawaii',
  ID: 'idaho',
  IL: 'illinois',
  IN: 'indiana',
  IA: 'iowa',
  KS: 'kansas',
  KY: 'kentucky',
  LA: 'louisiana',
  ME: 'maine',
  MD: 'maryland',
  MA: 'massachusetts',
  MI: 'michigan',
  MN: 'minnesota',
  MS: 'mississippi',
  MO: 'missouri',
  MT: 'montana',
  NE: 'nebraska',
  NV: 'nevada',
  NH: 'new-hampshire',
  NJ: 'new-jersey',
  NM: 'new-mexico',
  NY: 'new-york',
  NC: 'north-carolina',
  ND: 'north-dakota',
  OH: 'ohio',
  OK: 'oklahoma',
  OR: 'oregon',
  PA: 'pennsylvania',
  RI: 'rhode-island',
  SC: 'south-carolina',
  SD: 'south-dakota',
  TN: 'tennessee',
  TX: 'texas',
  UT: 'utah',
  VT: 'vermont',
  VA: 'virginia',
  WA: 'washington',
  WV: 'west-virginia',
  WI: 'wisconsin',
  WY: 'wyoming',
};

export const ATM_LOCATIONS_CHUNK_SIZE = 200;
export const ATM_LOCATIONS_KV_MAX_AGE = 60 * 1000; // 1 minute
